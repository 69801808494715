<template>
  <ClientOnly fallback-tag="span" fallback="Please wait...">
    <v-app>
      <v-navigation-drawer v-model="drawer" class="bg-background">
        <v-list>
          <v-list-item
            v-for="(item, index) in webStore.menu"
            :key="index"
            :title="item.menu.display_name"
            :prepend-icon="item.menu.icon"
            link
            nuxt
            :to="item.menu.route"
            class="text-secondary"
            @click="setAppBarTitle(item.menu.display_name)"
          />
        </v-list>
      </v-navigation-drawer>

      <v-app-bar :key="key" class="bg-background">
        <v-app-bar-nav-icon class="text-slate-50" @click="drawer = !drawer" />
        <v-app-bar-title class="text-secondary" :text="webStore.app_bar_title" />

        <v-spacer />
        <v-menu>
          <template #activator="{ props }">
            <v-avatar v-bind="props">
              <v-icon class="bg-slate-50 rounded-circle">
                mdi-account
              </v-icon>
            </v-avatar>
          </template>
          <v-list>
            <v-list-item @click="goToProfile">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToPreference">
              <v-list-item-title>Preference</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main class="bg-background">
        <slot />
      </v-main>
    </v-app>
  </ClientOnly>
</template>

<script setup>
import { ref } from 'vue'
import LogUserActivity from '~/src/services/logs/log_user_activity'

const drawer = ref(null)
const webStore = useWebsiteStore()
const key = ref(0)
const logUserActivity = new LogUserActivity()

function goToProfile () {

}

function goToPreference () {

}

const logout = async () => {
  try {
    const website = useWebsiteStore()
    const contract = useContractStore()

    logUserActivity.create('logout', website.user.user_hash_id, import.meta.url, { username: website.user.username, email: website.user.email })

    website.$reset()
    contract.$reset()

    await navigateTo('/')
  } catch (error) {
    const website = useWebsiteStore()

    throw createError({
      statusCode: 500,
      statusMessage: error
    })
  }
}

const setAppBarTitle = (title) => {
  webStore.setAppBarTitle(title)
  key.value++
}

</script>
